.section {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.section > img {
	width: 100%;
	height: 321px;
}

.section h2 {
	font-family: "Bebas Neue", sans-serif;
	font-size: 28px;
	line-height: 100%;
	font-weight: 400;
	color: #3f3f3f;
	margin: 26px 0 12px;
}

.section p {
	font-size: 14px;
	line-height: 20px;
	margin: 0 0 28px;
	color: #3f3f3f;
	max-width: 335px;
}

.section p a {
	color: var(--green-02);
}

.section > button {
	width: 141px;
}

@media (min-width: 768px) {
	.section {
		height: calc(100vh - 86px);
	}

	.section > img {
		height: 301.5px;
	}

	.section h2 {
		font-size: 48px;
		margin: 32px 0 12px;
	}

	.section p {
		font-size: 16px;
		line-height: 24px;
		max-width: 602px;
	}

	.section > button {
		width: 148px;
	}
}
