.loader__container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.loader {
	width: 48px;
	height: 48px;
	border: 5px solid var(--grey-02);
	border-bottom: 5px solid var(--grey-06);
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 0.7s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
