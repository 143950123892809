.header {
	padding: 20px 20px 0;
}

.header img {
	height: 37px;
	width: 37px;
}

.main {
	padding: 130px 20px 0;
	text-align: center;
	height: fit-content;
	border: 1px solid white;
}

.main > img {
	width: 100%;
	height: 224px;
}

.main h1 {
	font-family: "Bebas Neue", sans-serif;
	font-size: 20px;
	line-height: 20px;
	font-weight: 400;
	color: #263238;
	margin: 0 0 26px;
}

.main p {
	font-size: 14px;
	line-height: 20px;
	margin: 0 0 28px;
	color: #3f3f3f;
}

.main button:first-of-type {
	display: flex;
	column-gap: 5px;
	align-items: center;
	padding: 7px 20px;
	border: none;
	background: none;
	color: var(--green-02);
	margin: 0 auto 10px;
}

.main button:first-of-type:hover {
	box-shadow: none;
}

.main button:first-of-type img {
	width: 20px;
	height: 20px;
}

.main button:last-of-type {
	display: block;
	width: 141px;
	margin: 0 auto 17px;
}

.main a {
	display: block;
	color: var(--green-02);
	margin-bottom: 30px;
}

@media (min-width: 768px) {
	.header {
		padding: 36px 36px 0;
	}

	.header img {
		height: 60px;
		width: 60px;
	}

	.main {
		padding: 97px 0 0;
	}

	.main > img {
		width: 596px;
		height: 403px;
	}

	.main h1 {
		font-size: 29px;
		line-height: 29px;
	}

	.main p {
		font-size: 16px;
		line-height: 24px;
		max-width: 590px;
		margin: 0 auto 28px;
	}

	.main button:last-of-type {
		width: 148px;
	}
}
